.contacts{
    width: 100vw;
    height: 100vh;
    background: linear-gradient(135deg, rgba(23,16,10,1) 25%, rgba(75,62,62,1) 43%, rgba(134,77,27,1) 100%);
    background-image: url('../images/hero7.JPG');
    background-repeat: no-repeat;
    background-size: cover; 

}

.contacts-container{
   
    width: 80%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    display: flex;
    justify-content: center; 
    align-items: center;
    position: relative;
    top:6%;
   
}

.contacts-info{
display: flex;
flex-direction: column;
scale: 1.3;
}


.contacts-left-header{
    font-size: 50px;
 }

.left{
    color:#8f521a;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    align-items: center;
    justify-content: center;
    /* justify-content: center; */
    width: 600px;
    height: 500px;
   
}

.glass{
    background: rgba(255, 255, 255, 0.13);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);  
    text-shadow: -2px 4px 4px rgba(0,0,0,0.71);
}

.contacts-logo{
    width: 90px;
    position: relative;
    top:3%;
}


.contacts-left-header{
   margin-bottom: 40px;

}

.right{
    border: 1px solid;
    width: 600px;
    height: 500px;
    display: flex;
    justify-content: center; 
    align-items: center; 
    flex-direction: column;
    /* From https://css.glass */
background: rgba(255, 255, 255, 0.13);
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);
}


.contacts-info-icon{

    display: flex;
  position: relative;
  left: 50% ;
  margin-bottom: 20px;
  scale: 1.4;
}

.contacts-info-icon:hover{
transform: scale(1.2);
}

.control{
    width: 350px;
    margin-bottom: 30px;
   
}
.control:focus{
    background-color: transparent;
    border: 1px red;
}

.form-contacts{
    position: relative;
    top:4%;
    display: flex;
    flex-direction: column;
}

.form-button{
    width: 200px;
    height: 30px;
    background-color: transparent;
  margin: 30px auto 40px;
  border-radius: 10px;
  box-shadow: -1px 2px 13px 0px rgba(205,127,50,0.75);
  -webkit-box-shadow: -1px 2px 13px 0px rgba(205,127,50,0.75);
  -moz-box-shadow: -1px 2px 13px 0px rgba(205,127,50,0.75);
  transition: all .4s;
}

.form-button:hover{
    transform: translateY(5px) scale(1.14);
        box-shadow: -1px 2px 13px 8px rgba(205,127,50,0.75);
   -webkit-box-shadow: -1px 2px 13px 8px rgba(205,127,50,0.75);
   -moz-box-shadow: -1px 2px 13px 8px rgba(205,127,50,0.75);
   
}

@media screen and (max-width: 1050px){

.contacts-container{
    font-size: smaller;
}
.contacts-logo{
    position: relative;
    top:0px;
}

 
}

@media screen and (max-width: 950px){

   


    .contacts-left-header{
        font-size: 40px;
    }


    .control{
        width: 250px;
        margin-bottom: 30px;
       
    }
}

@media screen and (max-width: 800px){
    .contacts-left-header{
        font-size: 35px;
    }

    .contacts-info{
        font-size: 10px;
    }
}
@media screen and (max-width: 650px){
    .control{
        width: 200px;
        margin-bottom: 30px;
       
    }
}
@media screen and (max-width: 550px){
    .contacts{
        height: 1100px;
    }

    .left , .right{
        position: relative;
        top:-4%;
        width: 90%;
    }

   .contacts-container{
    position: relabsative;
    display: flex;
    flex-direction: column;
   }
   .contacts-left-header{
    font-size: 55px;
}

.contacts-info{
    font-size: 15px;
}
}


@media screen and (max-width: 440px){
    .contacts-left-header{
        font-size: 45px;
    }
}

@media screen and (max-width: 360px){
    .contacts-left-header{
        font-size: 35px;
    }
    .contacts-info{
        font-size: 10px;
    }

    .left , .right{
        position: relative;
        top:-4%;
        width: 98%;
    }
}