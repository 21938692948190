.photos-container{
 position: relative;
height: 100%;
display: flex;
flex-wrap: wrap;
}

.hero-photo{
    transition: transform 0.3s ease-in-out; /* Apply smooth transition */
    overflow: hidden;
    -webkit-box-shadow: 0px 5px 19px 31px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 5px 19px 31px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 5px 19px 31px rgba(0, 0, 0, 0.2);
}

.hero-photo:hover{
    transform:scale(1.05);

}


.hero-photos{
    position: relative;
/* top:20%; */
}