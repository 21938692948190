.faqs{
    overflow-x: hidden;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, rgba(23,16,10,1) 25%, rgba(75,62,62,1) 43%, rgba(134,77,27,1) 100%);
}

.questions{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
/* left: -4%; */
font-family: 'DM Sans', 'Arial Narrow', Arial, sans-serif;

}


.accordion-faqs{
    margin-bottom: 20px;
    -webkit-box-shadow: -11px 7px 42px 22px rgba(184, 115, 51, 0.2);
-moz-box-shadow: -11px 7px 42px 22px rgba(184, 115, 51, 0.2);
box-shadow: -11px 7px 42px 22px rgba(184, 115, 51, 0.2);
transition: all .3s ease-in-out;
}

.accordion-faqs:hover{
    transform: scale(1.04);}

.about-header{
    font-family: 'Roboto',sans-serif;
}

.faqs-span{
    font-size: larger;
    color: var(--chocolate);
}