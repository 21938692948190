.lessons{
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(95,67,50,1) 19%, rgba(23,16,10,1) 41%, rgba(87,61,46,1) 58%, rgba(75,62,62,1) 68%, rgba(134,77,27,1) 100%);

}

.lessons-header{
    position: absolute;
    top:10%;
    background: -webkit-radial-gradient(circle farthest-corner at left center, #B87333 21%, #b87222 76%);
    background: -moz-radial-gradient(circle farthest-corner at left center, #B87333 21%, #000000 76%);
    background: radial-gradient(circle farthest-corner at left center, #B87333 21%, #928e87 76%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 20px 17px 8px rgba(184,115,51,0.9);
}


.lessons-container{ 
    position: relative;
    /* padding: 30px; */
    width: 100%;
    height: 3300px; 
      background-image: url('../images/aogm.png'); 
      background-attachment: fixed;

       background-repeat: no-repeat;
       background-size: cover;
       display: flex; /* Use flexbox for alignment */
       justify-content: center; /* Center horizontally */
       align-items: center; /* Center vertically */
    
      }
    
    .lessons-list{
        margin-top: 80px;
         display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 40px;
    
    }
    
    
    .lessons-header{
      position: absolute;
      top:20%;
      left: 90px;
    } 

    .lessons-subheader{
        width: 330px;
        color: var(--bronze1);
    }

    .home-video{
        background-color: rgba(255, 255, 255, 0.1); /* Transparent white */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 20px 20px 20px rgba(0, 1, 11, 0.37); /* Box shadow */
  backdrop-filter: blur(9px);
    }


    @media screen and (max-width: 1300px){

            .lessons-container{
                position: relative;
                top:50px;
                height: 5090px;
            }

        .lessons-list{
            margin-top: 20px;
             display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 100px;
            grid-row-gap: 40px;
        
        }
    }


    @media screen and (max-width: 1400px){
        .lessons-list{
            margin-top: 20px;
             display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 70px;
            grid-row-gap: 10px;
        
        }  
        .lessons-container{
          
            height: 3700px;
        }
    }

    @media screen and (max-width: 1150px){
.lessons-list{
    grid-column-gap: 10px;
}
    }

    @media screen and (max-width: 1030px){
        .lessons-list{
            margin-top: 20px;
             display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 120px;
            grid-row-gap: 10px;
        
        } 
        .lessons-container{
          
            height: 5380px;
        }
    }



    @media screen and (max-width: 960px){
        .lessons-list{
            margin-top: 20px;
             display: grid;
            grid-column-gap: 50px;
            grid-row-gap: 40px;
        
        }
        .lessons-container{
            height: 5800px;
        }
    }

    

    @media screen and (max-width: 740px){
         .lessons-header{
position: absolute;
left: 2%;
}
.lessons-list{
    margin-top: 20px;
     display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;

} 
.lessons-container{
    height: 12180px;
}

    }


    @media screen and (max-width: 400px){
.lessons-subheader{
    font-size: 10px;
}
    }
   