
.notes{
    width: 100vw;
    display: flex;
    flex-direction: column;
    padding: 50px;
    position: relative;
    height: 1100px;
    background: linear-gradient(135deg, rgba(23,16,10,1) 25%, rgba(75,62,62,1) 43%, rgba(134,77,27,1) 100%);
}

.pdf-viewer{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 50px;    
    border: 2px solid #b87333;
    justify-content: space-between;
    margin: 10px 0;
    padding: 5px;
    color: #b87333;
    border-radius: 5px;
    background-color: transparent;
    transition: all .5s;
    background: rgba(255, 255, 255, 0.13);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);  
    text-shadow: -2px 4px 4px rgba(0,0,0,0.71);
}

.pdf-viewer:hover{
    background-color: #a8825e;
    color: black;
}


.national{
    position: relative;
         display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 20px;
    
}





.composer{
    position: relative;
    display: grid;
   grid-template-columns: repeat(2, 1fr);
   grid-template-rows: repeat(2, 1fr);
   grid-column-gap: 20px;
   grid-row-gap: 20px;
}



.note-title{
    margin-left: 20px;
    font-family: "Montserrat",sans-serif;

}


.notes-header{
    font-family: "Montserrat",sans-serif;

margin: 0 auto;   
 padding: 4px;
 width: 80%;
    background-color: #b87333;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-top: 50px;
    margin-bottom: 20px;
    box-shadow: -1px 2px 13px 8px rgba(225, 216, 206, 0.75);
    -webkit-box-shadow: -1px 2px 13px 8px rgba(220, 209, 198, 0.75);
    -moz-box-shadow: -1px 2px 13px 8px rgba(220, 205, 189, 0.75);
    
}

/* button */
.note-button{
    font-family: "Montserrat",sans-serif;

    background-color: #b87333;
    border: none;
    width: 130px;
    height: 40px;
    border-radius: 5px;
    transition: background-color .3s;
}

.note-button:hover{
    background-color: #c79c73;
    transform: scale(.95);
}




.pdf-modal-close{
    scale: 1.2;
    transition: scale 0.3s;
}

.pdf-modal-close:hover{
    scale: 1.2;
}

@media screen and (max-width: 830px){

    .notes{
        height: 1650px;
    }
.national{
    display: flex;
    flex-direction: column;
}
.composer{
    display: flex;
    flex-direction: column;
}
}

@media screen and (max-width:680px){
.note-button{
    width: 100px;
    height: 30px;
    border-radius: 5px;
}
}



@media screen and (max-width:600px){
    .notes-header{
        font-size:20px;
    }
    .pdf-viewer{
        padding: 2px;
    }
}