
.navbar{
    /* margin-top: 10px 20px; */
    width: 100%;
    height: 70px;
    overflow-y: hidden;
    display: flex;
    flex-direction: row;
    top: 0;
 justify-content: space-between;
 align-items: center;
 position: fixed;
/* From https://css.glass */
background: rgba(255, 255, 255, 0.14);
/* From https://css.glass */
background: rgba(255, 255, 255, 0);
/* border-radius: 16px; */
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(11.2px);
-webkit-backdrop-filter: blur(11.2px);
z-index: 100;
overflow-x: hidden;
}


.logo{
   
    transition: all .3s ease;
}


.logo:hover{
    transform: scale(1.04);}



.navbar-left{
    align-items: center;
    justify-content: center;

}

.navbar-right{
    display: flex;
    flex-direction: row;
    gap: 0px;
    position: absolute;
    right:12%;

}




.navlink{

    align-items: center;
    justify-content: center;
    text-decoration: none;
  
    color: var(--bronze1);
    border-bottom: 1px var(--bronze1);
    padding: 5px;
    margin-left: 30px;
    font-family: 'Girassol', sans-serif;
    font-weight: 400;
    transition: all .4s;
    border-radius: 10px;
    box-shadow: -1px 2px 13px 0px rgba(205,127,50,0.75);
    -webkit-box-shadow: -1px 2px 13px 0px rgba(205,127,50,0.75);
    -moz-box-shadow: -1px 2px 13px 0px rgba(205,127,50,0.75);
    transition: all .4s;
}
.navlink:hover{
    transform: scale(1.1)  ;
  
 box-shadow: -1px 2px 13px 8px rgba(205,127,50,0.75);
-webkit-box-shadow: -1px 2px 13px 8px rgba(205,127,50,0.75);
-moz-box-shadow: -1px 2px 13px 8px rgba(205,127,50,0.75);
}
   
.icon{
    position: relative;
    top:4px;
    transition: all .4s;
    color: var(--bronze1);
    background-color: transparent;

}


.hero-header{
    text-decoration:none;
    text-shadow: -6px 7px 6px rgba(0,0,0,0.6);
    width: 330px;
    background: #B87333;
    background: -webkit-radial-gradient(circle farthest-corner at left center, #B87333 21%, #000000 76%);
    background: -moz-radial-gradient(circle farthest-corner at left center, #B87333 21%, #000000 76%);
    background: radial-gradient(circle farthest-corner at left center, #B87333 21%, #412e2e 76%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
}

/* side bar */


.sidenav{
    top:-100%;
    /* height:0vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #B87333;
    text-align: center;
    background: linear-gradient(135deg, rgb(0, 0, 0) 0%, rgba(95,67,50,1) 100%, rgba(87,61,46,0.7651435574229692) 36%, rgb(11, 8, 8) 68%, rgba(134,77,27,1) 100%);
    transition: all 0.4s ease;
}

.sidenav.active{
    position: absolute;
    width: 100%;
    top:6%;
    height: 95vh;

   
}

.menu-open-icon-div{
    display: flex;
    flex-direction: row;
    /* position: fixed; */
    justify-content:space-between;
    background: linear-gradient(135deg, rgba(23,16,10,1) 0%, rgba(95,67,50,1) 0%, rgba(87,61,46,0.7651435574229692) 36%, rgba(75,62,62,1) 68%, rgba(134,77,27,1) 100%);
    width: 100vw;
    z-index: 111;
}

.sidelink{
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #B87333;
    margin: 7px;
    width: 80%;
    height: 60px;
    border-radius: 5px;
padding: 4px;
box-shadow: -1px 2px 13px 8px rgba(205,127,50,0.75);
-webkit-box-shadow: -1px 2px 13px 8px rgba(96, 58, 19, 0.75);
-moz-box-shadow: -1px 2px 13px 8px rgba(197, 124, 36, 0.75)
}

.sidelink:hover{
    box-shadow: -1px 2px 13px 8px rgba(205,127,50,0.75);
-webkit-box-shadow: -1px 2px 13px 8px rgba(24, 19, 14, 0.75);
-moz-box-shadow: -1px 2px 13px 8px rgba(12, 7, 1, 0.75);
color: #c39264;
}

/* language bar */

select{
    background-color: #412e2e;
    color: #B87333;
    height: 5vh;
    border-radius: 15px;
    padding-left: 10px;
}

select option{
    color: #c39264;
    border-radius: 5px;
    margin: 10px;
}

/* language */

.lng-container{
    /* background-color: #B87333; */
    width: 90px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px;
    margin-right: 30px;
}

.lng{
    padding: 5px;
    color: #412e2e;
    background-color: #B87333;
    border-radius: 40%;
}

.lng.selected{
    box-shadow: -1px 2px 13px 0px rgba(205,127,50,0.75);
    -webkit-box-shadow: -1px 2px 13px 0px rgba(205,127,50,0.75);
    -moz-box-shadow: -1px 2px 13px 0px rgba(205,127,50,0.75);
    color: #c39264;
}