@import url("css//home.css");
@import url("css//navbar.css");
@import url("css//footer.css");
@import url("css//faqs.css");
@import url("css//about.css");
@import url("css//lessons.css");
@import url("css//photos.css");
@import url("css//notlar.css");
@import url("css//contcats.css");
@import url("css//books.css");
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body {
  margin: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto",sans-serif;
  font-style: italic;
}

