.footer-container{
    width: 100vw;
   
background: rgb(95,67,50);
background: linear-gradient(135deg, rgba(95,67,50,1) 19%, rgba(23,16,10,1) 41%, rgba(87,61,46,1) 58%, rgba(75,62,62,1) 68%, rgba(134,77,27,1) 100%);
}



.footer{
width: 100vw;
 height: 400px;
background-color: var(--tertiary-black);
color: black;
display: flex;
flex-direction: column;
overflow-x: hidden;
-webkit-clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
}

.footer-logo{
    margin: 20px;
    width: 240px;
    height: 110px;
}

.footer-info-container{
    color: var(--bronze1);
    position: relative;
    /* margin: 0 auto; */
    justify-content: center;
    align-items: center;
}

.footer-info{
    margin: 10px 0;
}

.footer-up{
    display: flex;
    justify-content: space-around;
}


.footer-up-right{
    margin: auto;
    display: flex;
    justify-content: space-around;
}

.rights{
    position: relative;
    margin: 7px auto;
    background-color: var(--tertiary-black);
}

.rights-info{
    font-size: 20px;
    font-family:'Times New Roman', Times, serif;   
    color: var(--bronze1);
    justify-content: center;
    align-items: center;

}

.footer-icon{
    position: relative;
    top:5px;
}


.footer-down{
    background-color: black;
    height: 50px;
    padding: 10px;
    display: flex;

    align-items: center;
    justify-content: space-around;
}

.footer-down-icon{
       position: relative;
    transition: all .4s;
    color: var(--bronze1);
    background-color: transparent;
}

.footer-down-icon:hover{
    color:#bea790;

}

.footer-link{
    transition: all .4s;

}

.footer-link:hover{
    transform: scale(1.3)  ;
 }

@media screen and (max-width: 600px){
    .rights-info{
        font-size: 16px;
    }
}
@media screen and (max-width: 490px)
{

    .footer-logo{
        margin: 20px;
        width: 200px;
        height: 90px;
    }
    .footer-info{
        font-size: 15px;
        align-items: center;
    }
    .rights-info{
        font-size: 13px;
    }
}


@media screen and (max-width: 420px){
    .footer-logo{
        margin: 20px;
        width: 160px;
        height: 70px;
    }
    .footer-info{
        font-size: 12px;
        align-items: center;
    }
    .rights-info{
        font-size: 10px;
    }
}

@media screen and (max-width: 380px){
    .rights-info{
        font-size: 10px;
    }

    .footer{
        height: 325px;
    }
}

@media screen and (max-width: 345px){

    .footer-info{
        font-size: 9px;
        align-items: center;
    }
}