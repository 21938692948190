
.books-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    /* top: 30px; */
    padding-top: 50px;
    padding-bottom: 50px;
    min-height: 100vh; /* Ensures it takes at least the full viewport height */
    background: linear-gradient(135deg, rgba(95,67,50,1) 19%, rgba(23,16,10,1) 41%, rgba(87,61,46,1) 58%, rgba(75,62,62,1) 68%, rgba(134,77,27,1) 100%);

  }
  
  .books {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    top:30px;
    gap: 90px;
    max-width: 1000px; /* Limits the width */
    margin: 0 auto;
  }
  
  .book-card {
    max-width: 300px;
    height: 100%;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);
    transition: all 0.3s ease, box-shadow 0.3s ease;
    border-radius: 12px;
    overflow: hidden;
    backdrop-filter: blur(10px);
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.book-card:hover {
    transform: scale(1.05);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.4);
}
  
  .books-title {
    font-weight: bold;
    text-align: center;
    position: relative;
    top:24px;
    color: chocolate;
  }
  
  .book-title {
    font-family: 'Cursive', sans-serif;
    font-weight: bold;
    color: #ffdb58;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
    margin-top: 10px;
}

  .book-description {
    text-align: center;
  }
  


  .book-slider {
    width: 100%;
    height: 360px;
  }
  
  .carousel-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;    
    background-color: #5f4332;
    height: 100%;
}
  
  .carousel-image {
    
    width: 100%;
    height: auto;
    max-height: 350px;
    object-fit: cover;

  }
  
  .card-content{
    background: linear-gradient(135deg, rgba(95,67,50,1) 19%, rgb(127, 122, 117) 41%, rgba(87,61,46,1) 58%, rgba(75,62,62,1) 68%, rgba(134,77,27,1) 100%);
    height: 100%;
  }


  @media screen and (max-width:680px){
    .books-title{
      top:5px;
    }
    }
    