


.about{
  width: 100vw;
  height: 110%;
padding: 120px 40px 40px;
margin: 0 auto; /* This is the key for horizontal centering */
text-align: center; 
background: linear-gradient(135deg, rgba(23,16,10,1) 0%, rgba(95,67,50,1) 0%, rgba(87,61,46,0.7651435574229692) 36%, rgba(75,62,62,1) 68%, rgba(134,77,27,1) 100%);

}

.about-img{
    position: relative;
    left: auto;
width: 70%;
height: 300px;
margin: 10px;
box-shadow: -1px 2px 13px 8px rgba(205,127,50,0.75);
-webkit-box-shadow: -1px 2px 13px 8px rgba(24, 19, 14, 0.75);
-moz-box-shadow: -1px 2px 13px 8px rgba(12, 7, 1, 0.75);
}


.about-p{
    color: #b87333;
}
.about-img {
    --_g: 10% /45% 45% no-repeat linear-gradient(#000 0 0);
    --m:
      left   var(--_i,0%) top    var(--_g),
      bottom var(--_i,0%) left   var(--_g),
      top    var(--_i,0%) right  var(--_g),
      right  var(--_i,0%) bottom var(--_g);
    -webkit-mask: var(--m);
            mask: var(--m);
    transition: .3s linear;
    cursor: pointer;
  }
  .about-img:hover {
    --_i: 10%;
    filter: grayscale(0);
  }
  
  .about-imgage-container {
    margin: 0;
    background: #c9697a;
    display: grid;
    min-height: 100vh;
    grid-auto-flow :column;
    place-content: center;
    gap: 40px;
  }


  @media screen and (max-width: 1060px){
    .about{
      padding: 60px 20px 20px;
      height: 1200px;
    }
  }
  @media screen and (max-width: 940px){
    .about{
      height: 1300px;
    }
  }
  @media screen and (max-width: 815px){
    .about{
      height: 1400px;
    }
  }
  @media screen and (max-width: 815px){
    .about{
      height: 1500px;
    }
  }
  @media screen and (max-width: 680px){
    .about{
      height: 1700px;
    }
  }
  @media screen and (max-width: 610px){
    .about{
      height: 1900px;
    }
  }
  @media screen and (max-width: 540px){
    .about{
      height: 2100px;
    }
  }
  @media screen and (max-width: 450px){
    .about{
      height: 2300px;
    }
  }
  @media screen and (max-width: 402px){
    .about{
      height: 2700px;
    }
  }
  @media screen and (max-width: 350px){
    .about{
      height: 3000px;
    }
  }

